import { HttpErrorResponse } from '@angular/common/http';
import {
  EnvironmentInjector,
  inject,
  Injectable,
  runInInjectionContext,
  type ErrorHandler,
} from '@angular/core';
import { environment } from '../../../environments/environment';
import { SharedFacade } from '../../shared/store/shared.facade';
import { ERROR_DETAILS_KEY } from '../consts/error.consts';
import { getErrorDetails } from '../helpers/get-error-details.helper';
import { getErrorMessage } from '../helpers/get-error-message.helper';
import { LoggerService, MessageLevel } from './logger.service';

// TODO: enable
const shouldShowErrorSnackbar =
  (false as boolean) && (environment.isLocal || environment.isDev);

// TODO: Maybe create a script that can be run locally to show the code in the original file (using source maps) ?

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  readonly #environmentInjector = inject(EnvironmentInjector);
  readonly #logger = inject(LoggerService);

  handleError(error: unknown): void {
    console.error(error);

    if (error instanceof HttpErrorResponse) {
      return;
    }

    this.#logger.log({
      level: MessageLevel.Error,
      message: getErrorMessage(error),
      data: {
        [ERROR_DETAILS_KEY]: getErrorDetails(error),
      },
      shouldBeLoggedToConsole: false,
    });

    if (shouldShowErrorSnackbar) {
      try {
        runInInjectionContext(this.#environmentInjector, () => {
          inject(SharedFacade).enqueueErrorSnackbar({
            translationKey:
              'An <b>Error</b> was thrown. Please check the console for more information.',
            // TODO: move to a constant
            priority: 1_000_002,
          });
        });
      } catch (error: unknown) {
        console.error(error);
      }
    }
  }
}
