import type { Routes } from '@angular/router';
import { UNSUBSCRIBE_NOTIFICATIONS_TOKEN_PARAMETER } from './consts/unsubscribe.consts';
import { authGuard } from './guards/auth.guard';
import { checkAuthenticatedGuard } from './guards/check-authenticated.guard';

export const ROUTES: Routes = [
  {
    path: 'login',
    loadComponent: () =>
      import('./containers/login/login.container').then(
        (module) => module.LoginContainerComponent,
      ),
    canActivate: [checkAuthenticatedGuard],
  },
  {
    path: 'login-external',
    loadComponent: () =>
      import('./containers/external-login/external-login.component').then(
        (module) => module.ExternalLoginComponent,
      ),
  },
  {
    path: 'login-sso',
    loadComponent: () =>
      import('./containers/login-with-sso/login-with-sso.container').then(
        (module) => module.LoginWithSSOContainerComponent,
      ),
    canActivate: [checkAuthenticatedGuard],
  },
  {
    path: 'sso/:accountName',
    loadComponent: () =>
      import(
        './containers/login-with-sso-by-link/login-with-sso-by-link.container'
      ).then((module) => module.LoginWithSsoByLinkContainerComponent),
    canActivate: [checkAuthenticatedGuard],
  },
  {
    path: 'logout-external',
    loadComponent: () =>
      import('./containers/external-logout/external-logout.component').then(
        (module) => module.ExternalLogoutComponent,
      ),
  },
  {
    path: 'verify/:hashKey',
    loadComponent: () =>
      import('./containers/verify-login/verify-login.component').then(
        (module) => module.VerifyLoginComponent,
      ),
  },
  {
    path: 'verifyActions/:hash',
    loadComponent: () =>
      import(
        './containers/verify-action-login-container/verify-action-login.container'
      ).then((module) => module.VerifyActionLoginContainerComponent),
  },
  {
    path: 'forgot-password',
    loadComponent: () =>
      import('./containers/forgot-password/forgot-password.container').then(
        (module) => module.ForgotPasswordContainerComponent,
      ),
  },
  {
    path: 'reset-password',
    loadComponent: () =>
      import('./containers/reset-password/reset-password.container').then(
        (module) => module.ResetPasswordContainerComponent,
      ),
  },
  {
    path: 'registerSupplier',
    redirectTo: 'register-supplier',
  },
  {
    path: 'register-supplier',
    loadComponent: () =>
      import('./containers/register-supplier/register-supplier.container').then(
        (module) => module.RegisterSupplierContainerComponent,
      ),
  },
  {
    path: 'logout',
    loadComponent: () =>
      import('./containers/logout/logout.component').then(
        (module) => module.LogoutComponent,
      ),
    canActivate: [authGuard],
  },
  {
    path: `unsubscribe/notifications/:${UNSUBSCRIBE_NOTIFICATIONS_TOKEN_PARAMETER}`,
    loadComponent: () =>
      import(
        './containers/unsubscribe-notifications/unsubscribe-notifications.container'
      ).then((module) => module.UnsubscribeNotificationsContainerComponent),
  },
  {
    path: 'invalid-link',
    loadComponent: () =>
      import('./containers/invalid-link/invalid-link.container').then(
        (module) => module.InvalidLinkContainerComponent,
      ),
  },
  {
    path: '**',
    redirectTo: '/404',
  },
];
