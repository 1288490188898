import type { Routes } from '@angular/router';
import { authGuard } from '../auth/guards/auth.guard';

export const ROUTES: Routes = [
  {
    path: '',
    canActivate: [authGuard],
    loadChildren: () =>
      import('../home/home.module').then((module) => module.HomeModule),
  },
];
