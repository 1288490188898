import {
  ApplicationRef,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  type AfterViewInit,
  type OnInit,
} from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  catchError,
  concat,
  filter,
  first,
  fromEvent,
  interval,
  map,
  merge,
  of,
  skip,
  switchMap,
  tap,
  timeout,
} from 'rxjs';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../../../auth/services/auth.service';
import { ReloadThisPageModalComponent } from '../../../shared/components/reload-this-page-modal/reload-this-page-modal.component';
import { Roles } from '../../../shared/consts/roles.enum';
import { SharedFacade } from '../../../shared/store/shared.facade';
import { ERROR_DETAILS_KEY } from '../../consts/error.consts';
import { getErrorDetails } from '../../helpers/get-error-details.helper';
import { DialogService } from '../../services/dialog.service';
import { LoggerService, MessageLevel } from '../../services/logger.service';

const serviceWorkerTag = '#SERVICE_WORKER';

@UntilDestroy()
@Component({
  selector: 'app-root-container',
  templateUrl: './app.container.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [RouterOutlet],
})
export class AppContainerComponent implements OnInit, AfterViewInit {
  readonly #applicationReference = inject(ApplicationRef);
  readonly #authService = inject(AuthService);
  readonly #dialog = inject(DialogService);
  readonly #logger = inject(LoggerService);
  readonly #router = inject(Router);
  readonly #sharedFacade = inject(SharedFacade);
  readonly #swUpdate = inject(SwUpdate);
  readonly #changeDetectorRef = inject(ChangeDetectorRef);

  readonly #ROUTES_THAT_SHOULD_NOT_DISPLAY_UPDATE_MESSAGE = [
    'register-supplier',
  ];

  ngOnInit(): void {
    this.#initializeNetworkAccessTracking();
    this.#monitorServiceWorkerUpdates();
  }

  ngAfterViewInit(): void {
    this.#initializePendoIfEnabled();
  }

  #initializeNetworkAccessTracking(): void {
    merge(
      of(navigator.onLine),
      fromEvent(window, 'online').pipe(map(() => true)),
    )
      .pipe(
        filter((hasNetworkAccess) => hasNetworkAccess),
        skip(1),
        untilDestroyed(this),
      )
      .subscribe(() => {
        this.#sharedFacade.enqueueSuccessSnackbar({
          translationKey: 'shared.onlineMessage',
        });
        this.#changeDetectorRef.markForCheck();
      });

    merge(
      of(navigator.onLine),
      fromEvent(window, 'offline').pipe(map(() => false)),
    )
      .pipe(
        filter((hasNetworkAccess) => !hasNetworkAccess),
        untilDestroyed(this),
      )
      .subscribe(() => {
        this.#sharedFacade.enqueueErrorSnackbar({
          translationKey: 'shared.offlineMessage',
        });
        this.#changeDetectorRef.markForCheck();
      });
  }

  #initializePendoIfEnabled(): void {
    if (!environment.usePendo) {
      return;
    }

    pendo.initialize();
  }

  // TODO: extract to a service
  #monitorServiceWorkerUpdates(): void {
    if (!this.#swUpdate.isEnabled) {
      return;
    }

    this.#checkForServiceWorkerUpdatesPeriodically();
    this.#listenForServiceWorkerVersionUpdatesEvents();
    this.#listenForServiceWorkerUnrecoverableStateEvents();
  }

  #checkForServiceWorkerUpdatesPeriodically(): void {
    concat(
      this.#applicationReference.isStable.pipe(
        first((isStable) => isStable === true),
        // TODO: investigate why this happens so often
        timeout({
          // NOTE: 1 minute
          first: 60_000,
          with: () =>
            of(false).pipe(
              tap(() => {
                this.#logger.log({
                  level: MessageLevel.Error,
                  message: `Application is not stable after 1 minute ${serviceWorkerTag}`,
                  data: {},
                });
              }),
            ),
        }),
      ),
      // NOTE: 1 hour
      interval(3_600_000),
    )
      .pipe(
        switchMap(() => this.#swUpdate.checkForUpdate()),
        catchError((error: unknown) => {
          this.#logger.log({
            level: MessageLevel.Error,
            message: `Error while checking for application update ${serviceWorkerTag}`,
            data: {
              [ERROR_DETAILS_KEY]: getErrorDetails(error),
            },
          });

          return of(false);
        }),
        untilDestroyed(this),
      )
      .subscribe(() => {
        this.#changeDetectorRef.markForCheck();
      });
  }

  #listenForServiceWorkerVersionUpdatesEvents(): void {
    this.#swUpdate.versionUpdates.subscribe((versionEvent) => {
      if (
        this.#ROUTES_THAT_SHOULD_NOT_DISPLAY_UPDATE_MESSAGE.some((route) =>
          this.#router.url.includes(route),
        )
      ) {
        return;
      }

      switch (versionEvent.type) {
        case 'NO_NEW_VERSION_DETECTED': {
          // NOTE: do nothing

          break;
        }
        case 'VERSION_DETECTED': {
          this.#logger.log({
            level: MessageLevel.Info,
            message: `New application version detected ${serviceWorkerTag}`,
            data: {},
          });

          break;
        }
        case 'VERSION_READY': {
          if (!this.#authService.checkIfLocalBrowserDataIsValid()) {
            // QUESTION: should we show a different message here?
            this.#authService.removeInformationFromStorage();

            void this.#router.navigate(['/login']);
          }

          const loggedInUserRole = this.#authService.getLoggedInUserRole();

          if (
            loggedInUserRole === Roles.AUDITOR ||
            loggedInUserRole === Roles.SUPPLIER
          ) {
            this.#dialog.open(ReloadThisPageModalComponent, {
              width: '400px',
            });
          } else {
            this.#sharedFacade.enqueueInfoSnackbar({
              translationKey: 'shared.versionReadyMessage',
              // TODO: move to a constant
              priority: 1_000_000,
              canBeDismissed: false,
            });
          }

          this.#logger.log({
            level: MessageLevel.Info,
            message: `New application version is ready ${serviceWorkerTag}`,
            data: {},
          });

          break;
        }
        case 'VERSION_INSTALLATION_FAILED': {
          this.#sharedFacade.enqueueInfoSnackbar({
            translationKey: 'shared.versionInstallationFailedMessage',
            // TODO: move to a constant
            priority: 1_000_001,
            canBeDismissed: false,
          });

          this.#logger.log({
            level: MessageLevel.Error,
            message: `Error while installing application update ${serviceWorkerTag}`,
            data: {
              [ERROR_DETAILS_KEY]: {
                name: versionEvent.error,
              },
            },
          });
          break;
        }
        default: {
          this.#logger.log({
            level: MessageLevel.Error,
            message: `Unknown service worker version event ${serviceWorkerTag}`,
            data: {
              [ERROR_DETAILS_KEY]: {
                name: (versionEvent as { type: unknown }).type,
              },
            },
          });

          break;
        }
      }
      this.#changeDetectorRef.markForCheck();
    });
  }

  #listenForServiceWorkerUnrecoverableStateEvents(): void {
    this.#swUpdate.unrecoverable
      .pipe(untilDestroyed(this))
      .subscribe((unrecoverableStateEvent) => {
        this.#dialog.open(ReloadThisPageModalComponent, {
          width: '400px',
        });

        this.#logger.log({
          level: MessageLevel.Error,
          message: `Unrecoverable state of service worker ${serviceWorkerTag}`,
          data: {
            [ERROR_DETAILS_KEY]: {
              name: unrecoverableStateEvent.reason,
            },
          },
        });

        this.#changeDetectorRef.markForCheck();
      });
  }
}
