import {
  HttpErrorResponse,
  type HttpEvent,
  type HttpHandler,
  type HttpInterceptor,
  type HttpRequest,
} from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { tap, type Observable } from 'rxjs';
import { unauthorizedRequest } from '../../auth/store/actions/auth.actions';

@Injectable()
export class CheckAuthorizationStatusInterceptor implements HttpInterceptor {
  readonly #store = inject(Store);

  intercept(
    request: HttpRequest<unknown>,
    handler: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    return handler.handle(request).pipe(
      tap({
        error: (error: unknown) => {
          if (error instanceof HttpErrorResponse && error.status === 401) {
            this.#store.dispatch(unauthorizedRequest());
          }
        },
      }),
    );
  }
}
