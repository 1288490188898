import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { ModuleRegistry } from '@ag-grid-community/core';
import { CsvExportModule } from '@ag-grid-community/csv-export';
import { GridChartsModule } from '@ag-grid-enterprise/charts';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { LicenseManager } from '@ag-grid-enterprise/core';
import { ExcelExportModule } from '@ag-grid-enterprise/excel-export';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import { SideBarModule } from '@ag-grid-enterprise/side-bar';
import { DEFAULT_DIALOG_CONFIG, DialogConfig } from '@angular/cdk/dialog';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { environment } from '../../environments/environment';
import { metaReducers } from '../core/store/reducers';
import { DEFAULT_MODAL_WIDTH } from './consts/components.consts';
import { effects, reducers } from './store';

ModuleRegistry.registerModules([
  ClientSideRowModelModule,
  ColumnsToolPanelModule,
  FiltersToolPanelModule,
  GridChartsModule,
  RowGroupingModule,
  SetFilterModule,
  SideBarModule,
  CsvExportModule,
  ExcelExportModule,
  MenuModule,
]);
LicenseManager.setLicenseKey(environment.agGrid.licenseKey);

@NgModule({
  imports: [
    EffectsModule.forFeature(effects),
    StoreModule.forFeature('shared', reducers, { metaReducers }),
  ],
  providers: [
    {
      provide: DEFAULT_DIALOG_CONFIG,
      useFactory: () => {
        const dialogConfiguration = new DialogConfig();
        dialogConfiguration.backdropClass = ['tw-bg-[rgba(50,_50,_50,_0.75)]'];
        dialogConfiguration.disableClose = true;
        dialogConfiguration.width = DEFAULT_MODAL_WIDTH;
        return dialogConfiguration;
      },
    },
  ],
})
export class SharedModule {}
