import { APP_INITIALIZER, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { JwtModule } from '@auth0/angular-jwt';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { environment } from '../../environments/environment';
import { AppInjector } from '../core/helpers/set-app-injector.helper';
import { LocalStorageService } from '../core/services/local-storage.service';
import { SharedService } from '../core/services/shared.service';
import { metaReducers } from '../core/store/reducers';
import { SharedModule } from '../shared/shared.module';
import { ROUTES } from './auth.routes';
import { effects, reducers } from './store';

export function tokenGetter(): string | null {
  const localStorage = AppInjector.get(LocalStorageService);

  return (
    localStorage.getItem('access_token') ??
    window.localStorage.getItem('access_token') ??
    // eslint-disable-next-line unicorn/no-null
    null
  );
}

@NgModule({
  imports: [
    SharedModule,
    RouterModule.forChild(ROUTES),
    JwtModule.forRoot({
      config: {
        tokenGetter,
        allowedDomains: environment.allowedDomains,
        disallowedRoutes: [],
      },
    }),
    StoreModule.forFeature('auth', reducers, { metaReducers }),
    EffectsModule.forFeature(effects),
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: (sharedService: SharedService) =>
        function () {
          return sharedService.initialize();
        },
      deps: [SharedService],
      multi: true,
    },
  ],
})
export class AuthModule {}
