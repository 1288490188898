import {
  APP_INITIALIZER,
  ErrorHandler,
  inject,
  Injector,
  NgModule,
} from '@angular/core';
import { setAppInjector } from './helpers/set-app-injector.helper';
import { CountriesService } from './services/countries.service';
import { GlobalErrorHandler } from './services/global-error-handler.service';

@NgModule({
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: (countryService: CountriesService) => () =>
        countryService.initializeInstanceCountries(),
      deps: [CountriesService],
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
  ],
})
export class CoreModule {
  constructor() {
    setAppInjector(inject(Injector));
  }
}
