import { inject } from '@angular/core';
import type { CanActivateFn } from '@angular/router';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../core/store';
import { AuthService } from '../services/auth.service';
import * as fromStore from '../store';

/**
 * @deprecated
 */
export const authGuard: CanActivateFn = (_route, state) => {
  const authService = inject(AuthService);
  const store = inject(Store);

  if (authService.isAuthenticated) {
    // NOTE: perform some actions before proceeding
    store.dispatch(fromStore.fetchProfile({ payload: authService.profile }));

    return true;
  } else {
    store.dispatch(
      fromRoot.go({
        payload: {
          path: ['login'],
          ...(['/404', '/logout'].every((url) => !state.url.startsWith(url)) ?
            {
              query: {
                next: state.url,
              },
            }
          : {}),
        },
      }),
    );

    return false;
  }
};
